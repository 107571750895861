import { Component } from '@angular/core';
import { globalState } from 'src/global';

@Component({
  selector: 'app-quienes-somos',
  templateUrl: './quienes-somos.component.html',
  styleUrls: ['./quienes-somos.component.scss']
})
export class QuienesSomosComponent {
  idioma: string = globalState.idioma_usuario

  constructor() {
  }
}
