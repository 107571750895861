import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';
import { FamiliaModel } from 'src/app/models/entity/familia.model';
import { SubfamiliaModel } from 'src/app/models/entity/subfamilia.model';
import { IActualizaSubfamilia, IInsertaSubfamilia } from 'src/app/models/interface/subfamilia.interface';
import { LoadService } from 'src/app/services/helpers/load.service';
import { FamiliaServices } from 'src/app/services/https/familia.services';
import { SubfamiliaServices } from 'src/app/services/https/subfamilia.services';

@Component({
  selector: 'app-intranet-subfamilias',
  templateUrl: './intranet-subfamilias.component.html',
  styleUrls: ['./intranet-subfamilias.component.scss'],
  providers: [SubfamiliaServices, FamiliaServices]
})
export class IntranetSubfamiliasComponent {
  es_listado: Boolean;
  subfamilia: SubfamiliaModel | null = null;
  familias: FamiliaModel[] = [];
  id: number = 0;
  data: SubfamiliaModel[] = [];
  registros: number = 100;
  pagina_actual: number = 1;
  total_paginas: number = 0;
  filtro: string = '';
  cargando: boolean = true;
  enlace: string = '';

  constructor(
    private _router: Router,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _modal_service: NgbModal,
    private _subfamilia_service: SubfamiliaServices,
    private _familia_service: FamiliaServices,
  ) {
    const { url } = this._router
    const segments = url.split('/');
    this.es_listado = segments.length === 3;
    if (!this.es_listado)
      if (segments[segments.length - 1] !== "nuevo") {
        this.id = Number(segments[segments.length - 1]);

      } else {
        this.cargando = false;
      }
  }

  async ngOnInit(): Promise<void> {
    if (this.es_listado) {
      try {
        const { total_paginas, pagina_actual, subfamilias } = await this._subfamilia_service.paginacion(this.pagina_actual, this.registros, this.filtro);
        this.data = subfamilias;
        this.total_paginas = total_paginas;
        this.pagina_actual = pagina_actual;
        this._loader.notifyLoadChange(true);
      } catch(e: any) {
        console.log(e);
      } finally {
        this._loader.notifyLoadChange(false);
      }
    } else {
      this.familias = await this.obtiene_familias()
      if (this.id !== 0) {
        try {
          this._loader.notifyLoadChange(true);
          this.subfamilia = await this.obtiene_subfamilia(this.id)
          console.log(this.subfamilia);
        } catch (e: any) {

        } finally {
          this._loader.notifyLoadChange(false);
          this.cargando = false
        }
      }
    }
  }

  async obtener_listado(pagina_actual: number, total_registros: number, filtro: string): Promise<{subfamilias: SubfamiliaModel[], pagina_actual: number, total_paginas: number}>
  {
    return await this._subfamilia_service.paginacion(pagina_actual, total_registros, filtro);
  }

  async obtiene_subfamilia(id: number): Promise<SubfamiliaModel> {
    try {
      return await this._subfamilia_service.ficha(id);
    } catch (e: any) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  async obtiene_familias(): Promise<FamiliaModel[]> {
    try {
      const {familias} = await this._familia_service.paginacion(1, 1000, '');
      return familias
    } catch (e: any) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  async recibe_filtro(filtro_recibido: any) {
    const { filtro_texto } = filtro_recibido
    const filtro = `WHERE (INSTR(articulo, '${filtro_texto}') > 0) OR (INSTR(proveedor, '${filtro_texto}') > 0) OR (INSTR(descripcion, '${filtro_texto}') > 0)`;
    const pagina = this.filtro === '' ? 1 : this.pagina_actual;
    this.filtro = filtro
    const { subfamilias, total_paginas, pagina_actual} = await this.obtener_listado(pagina, this.registros, filtro);
    this.data = subfamilias;
    this.total_paginas = total_paginas;
    this.pagina_actual = pagina_actual;
  }

  async recibe_pagina(pagina_recibida: number) {
    this.pagina_actual = pagina_recibida;
    const { subfamilias, total_paginas, pagina_actual} = await this.obtener_listado(this.pagina_actual, this.registros, this.filtro);
    this.data = subfamilias;
    this.total_paginas = total_paginas;
    this.pagina_actual = pagina_actual;
  }

  abrir_modal_borrado(id: number) {
    const modalRef = this._modal_service.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "subfamilia"
    modalRef.componentInstance.id = id
    modalRef.componentInstance.tabla = "subfamilias"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if (borrado_exitoso) {
        try {
          this._loader.notifyLoadChange(true);
          await this._subfamilia_service.elimina(id);
        } catch (e: any) {
          console.log(e);
          this._toast.error(e);
          this._loader.notifyLoadChange(false);
        } finally {
          this.ngOnInit();
        }
      }
    });
  }


  async recibe_nueva_subfamilia(evento: IInsertaSubfamilia) {
    try {
      this._loader.notifyLoadChange(true);
      const subfamilia = await this._subfamilia_service.inserta(evento)
      this._toast.success('Subfamilia creada')
      this._router.navigate(['/intranet/subfamilias'])
    } catch (e: any) {
      console.log(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async recibe_actualiza_subfamilia(evento: IActualizaSubfamilia) {
    try {
      this._loader.notifyLoadChange(true);
      const subfamilia = await this._subfamilia_service.actualiza(this.id, evento)
      if (subfamilia !== null) {
        this._toast.success('Subfamilia actualizada')
        this._router.navigate(['/intranet/subfamilias'])
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

}

