import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoadService } from 'src/app/services/helpers/load.service';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';

@Component({
  selector: 'app-intranet-configuracion-page',
  templateUrl: './intranet-configuracion-page.component.html',
  styleUrls: ['./intranet-configuracion-page.component.scss'],
  providers: [ConfiguracionService]
})
export class IntranetConfiguracionPageComponent {

  constructor(
    private _service: ConfiguracionService,
    private _loader: LoadService,
    private _toast: ToastrService
  ) {
  }

  formGroup = new FormGroup({
    horario1: new FormControl<string>('', [Validators.required]),
    horario1_en: new FormControl<string>('', [Validators.required]),
    horario1_fr: new FormControl<string>('', [Validators.required]),
    horario2: new FormControl<string>('', ),
    horario2_en: new FormControl<string>('', ),
    horario2_fr: new FormControl<string>('', ),
    telefono: new FormControl<string>('', ),
    telefono_whatsapp: new FormControl<string>('', ),
  });

  async ngOnInit(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      const cfg = await this._service.ficha_nueva(1);
      this.formGroup.patchValue({
        horario1: cfg.horario1,
        horario1_en: cfg.horario1_en,
        horario1_fr: cfg.horario1_fr,
        horario2: cfg.horario2,
        horario2_en: cfg.horario2_en,
        horario2_fr: cfg.horario2_fr,
        telefono: cfg.telefono,
        telefono_whatsapp: cfg.telefono_whatsapp,
      });
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);

    }
  }

  async on_submit() {
    const { horario1, horario1_en, horario1_fr, horario2, horario2_en, horario2_fr, telefono, telefono_whatsapp } = this.formGroup.value;

    if (horario1 === null || horario1 === undefined || horario1 === '') {
      this._toast.error("El campo horario mañana es necesario");
      return;
    }
    try {
      const guardado = await this._service.actualiza(horario1, horario2!!, telefono!!, telefono_whatsapp!!, horario1_en!!, horario1_fr!!, horario2_en!!, horario2_fr!!);
      this._toast.success("Configuración guardada")
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false)
    }

  }
}
