import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadService } from 'src/app/services/helpers/load.service';
import { obtainToken, saveToken, saveUser } from 'src/app/services/helpers/session.helper';
import { AuthService } from 'src/app/services/https/auth.services';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  providers: [AuthService]
})
export class LoginPageComponent {
  nombre_token_datos_usuario: string = "dsocnweioncwoqei_reinaldos";

  form = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    private _toast: ToastrService,
    private _loader: LoadService,
    private _router: Router,
    private _auth_service: AuthService
  ){
    const value = obtainToken();
    if (value !== "TokenInvalido") {
      this._router.navigate(['/intranet/articulos'])
    }
  }

  

  async inicia_sesion(): Promise<void> {
    // Comprobamos formulario
    const formulario_valido = this.comprueba_formulario();
    if (!formulario_valido && this.form.value.email === null && this.form.value.password === null) {
      return;
    }
    const email: string = this.form.value.email!!;
    const password: string = this.form.value.password!!;
    this._loader.notifyLoadChange(true)
    try {
      const { usuario, token } = await this._auth_service.login(email, password);
      if (saveToken(token) && saveUser(usuario)) {
        this._loader.notifyLoadChange(false);
        this._toast.success(
          `Bienvenido ${usuario.nombre}`,
          'Iniciada sesión!'
        );
        this._router.navigate(['/intranet/articulos']);
      }
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false)
    }

  }

  /**
   * Comprueba que el formulario sea valido
   * @returns boolean (Verificación de formulario valido)
   */
  comprueba_formulario(): boolean {
    if (this.form.value.email && this.form.value.email.length === 0) {
      this._toast.error('Email necesario')
      return false;
    }
    if (this.form.value.password && this.form.value.password.length === 0) {
      this._toast.error('Contraseña necesaria')
      return false;
    }
    return true;
  }
}
