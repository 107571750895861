import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faClose, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { FamiliaModel } from 'src/app/models/entity/familia.model';
import { IActualizaFamilia, IInsertaFamilia } from 'src/app/models/interface/familias.interface';
import { generateRandomName } from 'src/app/services/helpers/utils.helper';
import { FamiliaServices } from 'src/app/services/https/familia.services';

@Component({
  selector: 'app-intranet-familias-form',
  templateUrl: './intranet-familias-form.component.html',
  styleUrls: ['./intranet-familias-form.component.scss'],
  providers: [FamiliaServices]
})
export class IntranetFamiliasFormComponent {
  @Input() familia!: FamiliaModel | null;
  @Input() es_nuevo!: Boolean;
  @Input() id!: number;
  @Output() envia_nueva_familia: EventEmitter<IInsertaFamilia> = new EventEmitter<IInsertaFamilia>();
  @Output() envia_actualiza_familia: EventEmitter<IActualizaFamilia> = new EventEmitter<IActualizaFamilia>();

  imagen_a_mostrar: string = '';
  icono_cerrar: IconDefinition = faClose;
  imagen_para_form_data: File | undefined = undefined


  formGroup = new FormGroup({
    familia_enlace: new FormControl<string>('', [Validators.required]),
    idioma: new FormControl<string>('', [Validators.required]),
    familia: new FormControl<string>('', [Validators.required]),
    familia_en: new FormControl<string>(''),
    familia_fr: new FormControl<string>(''),
    descripcion: new FormControl<string>('', [Validators.required]),
    descripcion_en: new FormControl<string>(''),
    descripcion_fr: new FormControl<string>(''),
    file_name: new FormControl<string>('',),
  });

  constructor(
    private _toast: ToastrService,
    private _service: FamiliaServices
  ) { }

  ngOnInit() {
    if (!this.es_nuevo && this.familia !== null) {
      this.rellena_formulario(this.familia);
    }
  }

  rellena_formulario(familia: FamiliaModel): void
  {
    this.formGroup.patchValue({
      familia_enlace: familia.familia_enlace,
      familia: familia.familia,
      familia_fr: familia.familia_fr,
      familia_en: familia.familia_en,
      descripcion: familia.descripcion,
      descripcion_fr: familia.descripcion_fr,
      descripcion_en: familia.descripcion_en,
      file_name: familia.file_name,
    });
    this.imagen_a_mostrar = familia.file_name;
  }

  borra_imagen() {
    this.formGroup.patchValue({
      file_name: ''
    });
    this.imagen_a_mostrar = '';
    this.imagen_para_form_data = undefined
  }


  async on_submit() {
    const es_valido = await this.comprueba_formulario();
    if (es_valido) {
      if (this.es_nuevo) {
        this.formatea_nueva_familia();
      } else {
        this.formatea_actualiza_familia();
      }
    }
  }

  formatear_archivo(evento: any): void {
    const upload_event = evento.target;
    if (upload_event.files.length > 0) {
      // Solo gestionamos el primer archivo (puedes modificarlo para gestionar varios)
      const archivo: File = upload_event.files[0];
      const extension = archivo.name.split('.').slice(-1)[0].toLowerCase();

      // Genera un nombre aleatorio para el archivo
      const filename = `${generateRandomName()}.${extension}`;
      const archivo_modificado = new File([archivo], filename, { type: archivo.type })
      this.formGroup.patchValue({ file_name: filename });
      // Asigna el archivo formateado a la propiedad imagen_articulo

      this.imagen_para_form_data = archivo_modificado;

      // Verifica si es una imagen o un PDF para mostrar la vista previa
      if (['png', 'jpg', 'jpeg'].includes(extension)) {
        // Si es una imagen, genera una URL temporal para mostrar la vista previa
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imagen_a_mostrar = e.target.result

        };
        reader.readAsDataURL(archivo_modificado); // Lee el archivo como Data URL
      } else if (extension === 'pdf') {
        // Si es un PDF, también generamos una URL temporal
        const pdfUrl = URL.createObjectURL(archivo_modificado);
        this.formGroup.patchValue({
          file_name: pdfUrl // Establece la URL del PDF en el formulario
        });
      }
    }
  }

  formatear_para_enlace(evento: any) {
    if (this.es_nuevo) {
      const texto = evento.target.value;
      // Eliminar acentos y caracteres especiales
      const texto_normalizado = texto
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Eliminar acentos
        .replace(/[^a-z0-9\s-]/g, '') // Eliminar signos de exclamación y caracteres especiales
        .replace(/\s+/g, '-') // Reemplazar espacios por guiones
        .replace(/ñ/g, 'n'); // Reemplazar 'ñ' por 'n'

      this.formGroup.patchValue({
        familia_enlace: texto_normalizado
      });
    }
  }

  async comprueba_formulario(): Promise<Boolean> {
    const { familia, familia_enlace, descripcion, file_name } = this.formGroup.value
    if (familia === null || familia === undefined || familia === "") {
      this._toast.error('Campo familia requerido');
      return false
    }
    if (this.es_nuevo){
      if (familia_enlace === null || familia_enlace === undefined || familia_enlace === "") {
        this._toast.error('Campo familia enlace requerido');
        return false
      }
      const enlace_existe = await this.comprueba_existe_enlace(familia_enlace);
      if (enlace_existe) {
        this._toast.error('Este enlace ya existe, prueba con otro');
        return false;
      }
    }
    if (descripcion === null || descripcion === undefined || descripcion === "") {
      this._toast.error('Campo descripción requerido');
      return false
    }
    if (file_name === null || file_name === undefined || file_name === "") {
      this._toast.error('Imagen requerida');
      return false
    }
   
    return true;
  }

  async comprueba_existe_enlace(enlace: string): Promise<boolean> {
    try {
      const info = await this._service.obtiene_familia_por_enlace(enlace);
      return true
    } catch (e: any) {
      console.log(e)
      return false
    }
  }

  formatea_nueva_familia() {
    const { familia_enlace, familia, familia_en, familia_fr, descripcion, descripcion_en, descripcion_fr, file_name } = this.formGroup.value!!;
    const nueva_familia: IInsertaFamilia = {
      familia_enlace: familia_enlace!!,
      familia: familia!!,
      familia_en: familia_en!!,
      familia_fr: familia_fr!!,
      descripcion: descripcion!!,
      descripcion_en: descripcion_en!!,
      descripcion_fr: descripcion_fr!!,
      file_name: file_name!!,
      archivo: this.imagen_para_form_data!!
    };
    this.envia_nueva_familia.emit(nueva_familia);
  }

  formatea_actualiza_familia() {
    const { familia_enlace, familia, familia_en, familia_fr, descripcion, descripcion_en, descripcion_fr, file_name } = this.formGroup.value!!;
    const actualiza_familia: IActualizaFamilia = {
      uid: this.id,
      familia_enlace: familia_enlace!!,
      familia: familia!!,
      familia_en: familia_en!!,
      familia_fr: familia_fr!!,
      descripcion: descripcion!!,
      descripcion_en: descripcion_en!!,
      descripcion_fr: descripcion_fr!!,
      file_name: file_name!!,
      archivo: this.imagen_para_form_data || undefined
    };
    this.envia_actualiza_familia.emit(actualiza_familia);
  }
}
