import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NovedadModel } from 'src/app/models/entity/novedad.model';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { NovedadServices } from 'src/app/services/https/novedad.services';
import { SliderServices } from 'src/app/services/https/slider.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-inicio-page',
  templateUrl: './inicio-page.component.html',
  styleUrls: ['./inicio-page.component.scss'],
  providers: [SliderServices, NovedadServices]
})
export class InicioPageComponent {
  sliders: ModelSlider[] = [];
  novedades: NovedadModel[] = [];
  constructor(
    private _slider_services: SliderServices,
    private _novedad_services: NovedadServices,
    private _loader: LoadService,
    private _toast: ToastrService,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      this.sliders = await this._slider_services.lista();
      const filtro = this.crea_filtro();
      this.novedades = await this._novedad_services.lista(filtro);
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }


  crea_filtro(): string {
    const idioma = globalState.idioma_usuario.split('-')[0];
    return `where idioma = '${idioma}'`
  }
}
